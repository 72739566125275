import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ChevronDownIcon } from '@heroicons/react/outline';
import RecentAssets from './SectionHero';
import DashboardHeader from './SectionMain'

const Dashboard = () => {
  const [ativos, setAtivos] = useState({
    total_assets: 0,
    assets_in_use: 0,
    available_assets: 0,
    total_investment_value: 0,
  });
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = `Dashboard - PinnSystem`;
  
    const fetchAtivos = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://api.pinnsystem.com/api/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAtivos(response.data);
      } catch (error) {
        console.error("Erro ao buscar ativos:", error);
        setError("Erro ao buscar ativos.");
      } finally {
        setLoading(false);
      }
    };
  
    const fetchActivityData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://api.pinnsystem.com/api/dashboard/activity-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Dados de atividade:", response.data.activity_data); // Verifique os dados aqui
        setActivityData(response.data.activity_data);
      } catch (error) {
        console.error("Erro ao buscar dados de atividade:", error);
        setError("Erro ao buscar dados de atividade.");
      }
    };
    
  
    fetchAtivos();
    fetchActivityData();
  }, []);
  

  return (
    <div className="p-4 bg-gray-50 min-h-screen">
      <DashboardHeader />
      {loading ? (
        <div className="space-y-6">
          <div className="animate-pulse">
            <div className="h-6 bg-gray-300 rounded w-1/4"></div>
            <div className="h-10 bg-gray-300 rounded w-3/4"></div>
            <div className="h-8 bg-gray-300 rounded w-1/3"></div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-white p-4 rounded-lg shadow-md animate-pulse">
              <div className="h-6 bg-gray-300 rounded w-1/3"></div>
              <div className="h-8 bg-gray-300 rounded w-1/2 mt-2"></div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md animate-pulse">
              <div className="h-6 bg-gray-300 rounded w-1/3"></div>
              <div className="h-8 bg-gray-300 rounded w-1/2 mt-2"></div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md animate-pulse">
              <div className="h-6 bg-gray-300 rounded w-1/3"></div>
              <div className="h-8 bg-gray-300 rounded w-1/2 mt-2"></div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md animate-pulse">
              <div className="h-6 bg-gray-300 rounded w-1/3"></div>
              <div className="h-8 bg-gray-300 rounded w-1/2 mt-2"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col sm:flex-row justify-between mb-6 space-y-4 sm:space-y-0">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full">
              <CustomDropdown label="" options={['Todos os ativos', 'Notebooks', 'Monitores', 'Smartphones', 'Tablets', 'Periféricos']} />
              <CustomDropdown label="Suas licenças" options={['All', 'Licenças de Software', 'Contas a pagar']} />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <Card title="Quantidade de ativos" used={ativos.total_assets} total={50} />
            <Card title="Ativos em uso" value={`${ativos.assets_in_use} em uso`} showGraph percentage={(ativos.assets_in_use / ativos.total_assets) * 100} />
            <Card title="Ativos disponíveis" value={`${ativos.available_assets} disponíveis`} showGraph percentage={(ativos.available_assets / ativos.total_assets) * 100} />
            <Card title="Investimento em ativos" value={`R$ ${ativos.total_investment_value.toFixed(2).replace('.', ',')}`} />
          </div>
          <div className="bg-white rounded-lg p-4 sm:p-6 shadow-md">
            <div className="flex flex-col sm:flex-row justify-between mb-4">
              <h2 className="text-lg font-semibold">Atividade</h2>
              <CustomDropdown options={['Mês', 'Semana', 'Dia']} />
            </div>
            <ActivityChart activityData={activityData} />
          </div>
        </>
      )}
    </div>
  );
};

// For future

{/* <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm">
<h2 className="text-lg font-medium text-gray-700 mb-2">Ativos Disponíveis</h2>
<p className="text-3xl font-semibold text-gray-900">{ativos.available_assets} disponíveis</p>
<div className="mt-3">
  <div className="w-full bg-gray-100 rounded-full h-2.5">
    <div
      className="bg-green-500 h-2.5 rounded-full"
      style={{ width: `${(ativos.available_assets / ativos.total_assets) * 100}%` }}
    ></div>
  </div>
  <p className="text-sm text-gray-500 mt-2">
    {((ativos.available_assets / ativos.total_assets) * 100).toFixed(1)}% disponíveis
  </p>
</div>
</div>

<div className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm">
<h2 className="text-lg font-medium text-gray-700 mb-2">Investimento em Ativos</h2>
<p className="text-3xl font-semibold text-gray-900">
  R$ {ativos.total_investment_value.toFixed(2).replace('.', ',')}
</p>
<span className="text-sm text-gray-500">Valor total investido</span>
</div>
</div> */}

const CustomDropdown = ({ label, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full sm:w-64">
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between bg-white text-gray-700 py-2 px-4 rounded-lg shadow-sm w-full hover:bg-gray-50 focus:outline-none transition-all"
      >
        <span className="font-medium">{label} <span className="text-gray-900">{selectedOption}</span></span>
        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
      </button>
      {isOpen && (
        <div className="absolute top-full mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => selectOption(option)}
              className="w-full text-left px-4 py-2 text-gray-700 hover:text-white transition-all"
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const Card = ({ title, value, showGraph, percentage, used, total }) => {
  const progressPercentage = used && total ? (used / total) * 100 : percentage;

  return (
    <div className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between space-x-4">
      <div>
        <h3 className="text-gray-500 font-medium text-sm sm:text-base">{title}</h3>
        <p className="text-xl font-semibold sm:text-2xl">{value || `${used}/${total}`}</p>
      </div>
      {showGraph || (used !== undefined && total !== undefined) ? (
        <div className="w-12 h-12 sm:w-16 sm:h-16">
          <CircularProgressbar
            value={progressPercentage}
            text={`${Math.round(progressPercentage)}%`}
            styles={buildStyles({
              textColor: progressPercentage >= 100 ? "red" : "#3b82f6",
              pathColor: progressPercentage >= 100 ? "red" : "#3b82f6",
              trailColor: "#d6d6d6"
            })}
          />
        </div>
      ) : null}
    </div>
  );
};

const ActivityChart = ({ activityData }) => {
  const maxBarHeight = 80;
  const total_assets = activityData.reduce((sum, value) => sum + value.count, 0); // Total de ativos cadastrados
  const barWidth = 4; // Largura de cada barra

  return (
    <>
      <div className="w-full h-48 bg-gradient-to-b from-blue-100 to-gray-100 rounded-lg flex items-end space-x-2 px-4 py-4">
        {activityData.map((data, index) => {
          const barHeight = total_assets > 0 ? Math.min((data.count / total_assets) * maxBarHeight, maxBarHeight) : 0;


          return (
            <div
              key={index}
              className="bg-blue-500 rounded-t-lg transform hover:scale-105 transition-all duration-300 ease-in-out"
              style={{
                height: `${barHeight}%`,
                width: `${barWidth}px`,
                background: `linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%)`,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            ></div>
          );
        })}
      </div>
      <RecentAssets />
      <p className="text-center mt-4 text-sm text-gray-600">
        © 2025 PinnSystem. All rights reserved.
      </p>
    </>
  );
};

export default Dashboard;
